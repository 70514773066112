import React, { useState } from 'react'

import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks'
import Hero from '../components/Hero'
import { graphql, Link, useStaticQuery } from 'gatsby'
import FilterContext from '../context/FilterContext'

export default function Agenda() {
  const { title, description } = useSiteMetadata()
  const { allPerson } = useStaticQuery(graphql`
    query Speakers {
      allPerson {
        nodes {
          location
          gender
          country
          description
          id
          name
          tags
          excerpt
          twitter
          url
          company
          additionalSpeakers {
            name
            twitter
            url
            image
            company
          }
          image
        }
      }
    }
  `)

  const locations = ['Melbourne (Conf)', 'Sydney (Conf)']
  const [active, setActive] = useState(locations[0])

  return (
    <Layout
      title={`Speakers | ${title}`}
      description={description}
    >
      <Hero className={'hero is-transparent is-medium'} isIndex={false} title={'Agenda'}/>

      <div className={'columns has-padding-top-30 has-padding-bottom-30 has-padding-20-mobile'}>
        <div className={'column is-offset-one-quarter is-half is-offset-2-tablet is-8-tablet is-size-6'}>
          <div className={'columns has-text-centered'}>
            <div className={'column'}>
              <div className={'columns has-padding-bottom-30 is-multiline is-centered'}>
                <div className="tabs is-centered">
                  <ul>
                    {locations.map((type) => (
                      <li
                        className={active === type && 'is-active'}
                        key={type}
                        aria-hidden
                        onClick={() => setActive(type)}>
                        <a>{type}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={'columns has-padding-bottom-50 is-multiline is-centered is-size-6'}>
                {active === 'Melbourne (Conf)' && (
                  <>
                    <div className={'is-size-5 has-padding-bottom-20'}>
                      Melbourne Conference <br /> Tuesday, February 15, 2022
                    </div>
                    <table className="table is-bordered is-striped is-narrow">
                      <thead>
                      <tr className="">
                        <th>&nbsp;</th>
                        <th>Savoy</th>
                        <th>Mayfair</th>
                        <th>Savoy/Bristol</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>07:00 - 08:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Sponsors and exhibitors access to setup exhibitor area</span></td>
                      </tr>
                      <tr>
                        <td>08:15</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Registration opens for attendees</span></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Breakfast served in breakout area</span></td>
                      </tr>
                      <tr>
                        <td>09:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Welcome</span></td>
                      </tr>
                      <tr>
                        <td>09:05</td>
                        <td>Five lessons I've learnt building an open source developer tool<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Charles Korn</span></td>
                        <td>MLOps - CI/CD for Machine Learning<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Jessie Wei</span></td>
                        <td>Refactoring Components<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Erin Zimmer</span></td>
                      </tr>
                      <tr>
                        <td>09:55</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Morning Break</span></td>
                      </tr>
                      <tr>
                        <td>10:15</td>
                        <td>Quantum inspired optimizations<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>John Azaria</span></td>
                        <td>Digital Twins: Your Next Step in IoT<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Melissa Houghton</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>11:05</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>11:10</td>
                        <td>CI/CD are people too<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Sohrab Hosseini</span></td>
                        <td>Riding the stream: unlock your organization data as it scales<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Karel Alfonso & Emily
                          Namugaanyi</span>
                        </td>
                        <td>4 Steps from JavaScript to TypeScript<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Phil Nash</span></td>
                      </tr>
                      <tr>
                        <td>12:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Lunch</span></td>
                      </tr>
                      <tr>
                        <td>13:00</td>
                        <td>Tuning web performance with just browser APIs<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Yas Adel Mehraban</span></td>
                        <td>Building software with effective governance from day 1<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Rovan Omar</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>13:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>14:00</td>
                        <td>Dev/Ops relationships, status: It's Complicated<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Anna Fiofilova</span></td>
                        <td>The science, engineering, and art of building ML products<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Soon-Ee Ceah</span></td>
                        <td>Learn you some security for the great good! (keeping the bad guys at bay)<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Ronen Narkis</span>
                        </td>
                      </tr>
                      <tr>
                        <td>14:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>15:00</td>
                        <td>From the trenches: Well-Architected Framework at scale<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Isthi Ifran & Duncan Ross</span></td>
                        <td>Hacking JWTs<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Ben Dechrai</span></td>
                        <td>Privacy Engineering Techniques for cloud developers<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Jenny Yang</span></td>
                      </tr>
                      <tr>
                        <td>15:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Afternoon Break</span></td>
                      </tr>
                      <tr>
                        <td>16:20</td>
                        <td>Arrow to the heart: A year of functional programming in our Kotlin codebase<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Milly Rowett</span></td>
                        <td>Scaling online retail in a pandemic<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Alex Ong</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>17:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Post conference fun!</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </>
                )}

                {active === 'Sydney (Conf)' && (
                  <>
                    <div className={'is-size-5 has-padding-bottom-20'}>
                      Sydney Conference <br /> Thursday, February 17, 2022
                    </div>
                    <table className="table is-bordered is-striped is-narrow">
                      <thead>
                      <tr className="">
                        <th></th>
                        <th>Tribute</th>
                        <th>Tribeca</th>
                        <th>Harbour</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>07:00 - 08:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Sponsors and exhibitors access to setup exhibitor area</span></td>
                      </tr>
                      <tr>
                        <td>08:15</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Registration opens for attendees</span></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Breakfast served in breakout area</span></td>
                      </tr>
                      <tr>
                        <td>09:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Welcome</span></td>
                      </tr>
                      <tr>
                        <td>09:05</td>
                        <td>Quantum inspired optimizations<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>John Azaria</span></td>
                        <td>From the trenches: Well-Architected Framework at scale<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Isthi Ifran & Duncan Ross</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>09:55</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Morning Break</span></td>
                      </tr>
                      <tr>
                        <td>10:15</td>
                        <td>Refactoring Components<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Erin Zimmer</span></td>
                        <td>Digital Twins: Your Next Step in IoT<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Melissa Houghton</span></td>
                        <td>Using Kubernetes to Manage your Entire Cloud<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Jason O'Connell</span></td>
                      </tr>
                      <tr>
                        <td>11:05</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>11:10</td>
                        <td>CI/CD are people too<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Sohrab Hosseini</span></td>
                        <td>Riding the stream: unlock your organization data as it scales<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Karel Alfonso & Emily
                          Namugaanyi</span>
                        </td>
                        <td>Building software with effective governance from day 1<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Rovan Omar</span></td>
                      </tr>
                      <tr>
                        <td>12:00</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Lunch</span></td>
                      </tr>
                      <tr>
                        <td>13:00</td>
                        <td>Five lessons I've learnt building an open source developer tool<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Charles Korn</span></td>
                        <td>MLOps - CI/CD for Machine Learning<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Jessie Wei</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>13:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>14:00</td>
                        <td>Learn you some security for the great good! (keeping the bad guys at bay)<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Ronen Narkis</span>
                        </td>
                        <td>Hacking JWTs<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Ben Dechrai</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>14:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Session Transition</span></td>
                      </tr>
                      <tr>
                        <td>15:00</td>
                        <td>Hello, Operator: Extending Kubernetes<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Josh Vawdrey</span></td>
                        <td>Arrow to the heart: A year of functional programming in our Kotlin codebase<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Milly Rowett</span>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>15:50</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Afternoon Break</span></td>
                      </tr>
                      <tr>
                        <td>16:20</td>
                        <td>4 Steps from JavaScript to TypeScript<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Phil Nash</span></td>
                        <td>Privacy Engineering Techniques for cloud developers<br /><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Jenny Yang</span></td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>17:10</td>
                        <td colSpan={3}><span className={'is-size-7 is-uppercase has-text-weight-bold'}>Post conference fun!</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export { Agenda }
